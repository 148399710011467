html {
  /* 
    The Figma design is using canvas 2551 x 1316, which is unusually big.
    Therefore, let's use rem so that we can scale everything by changing one line.
   */
  font-size: 9px;
}

@media (min-width: 1440px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1760px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 2080px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 2240px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 2400px) {
  html {
    font-size: 16px;
  }
}

html {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

/* for debugging purpose */
/* body :hover {
  background-color: #fff4;
} */

body {
  background-color: #151515;
  color: #fff;
  box-sizing: border-box;
  margin: 0;
  padding: 8rem;
  height: 100vh;
  height: 100dvh;
}

main {
  background-image: linear-gradient(#000000, #821a79);
  border-radius: 0.5rem;
  box-shadow: 0 2rem 4.5rem 0 #d127d4a5, 0 2rem 2.5rem -0.5rem #ac10828c;
  height: calc(100vh - 16rem);
  height: calc(100dvh - 16rem);
  position: relative;
  min-height: 36rem;
}

@media (max-width: 720px) {
  body {
    background-color: none;
    background-image: linear-gradient(#000000, #821a79);
    padding: 4rem;
  }

  main {
    background-image: none;
    box-shadow: none;
    height: calc(100vh - 8rem);
    height: calc(100dvh - 8rem);
  }
}

.logo-container {
  position: absolute;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.5rem;
  gap: 1.25rem;
  left: 0;
  top: 0;
}

.logo-figure {
  width: 3.9375rem;
  height: 5.625rem;
}

.logo-text {
  width: 12.8025rem;
  height: 3.5625rem;
}

.social-container {
  position: fixed;
  right: 2.75rem;
  top: 1.75rem;
  display: flex;
  gap: 0.5rem;
}

.social-link {
  color: #ff00a6;
}

.social-link:hover {
  opacity: 80%;
}

.social-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 720px) {
  .social-container {
    top: 6rem;
    gap: 1rem;
  }

  .social-icon {
    width: 3rem;
    height: 3rem;
  }
}

.hero-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  translate: 0 -50%;
  text-align: center;
  padding: 1rem;
}

.hero-title {
  all: unset;
  display: block;

  font-weight: 600;
  font-size: 4rem;
}

.hero-subtitle {
  font-weight: 600;
  font-size: 2.5rem;
}

.footer-container {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 720px) {
  .footer-container {
    bottom: 2.5rem;
    right: auto;
    left: 3rem;
  }
}

.footer-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #f601a8;
}

.whitepaper-container {
  position: absolute;
  bottom: 2.5rem;
  right: 3rem;
}

.whitepaper-link {
  color: #fff;
  background-color: #9c00b0;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.whitepaper-link:hover {
  opacity: 80%;
}

.whitepaper-icon {
  width: 1.5rem;
  height: 1.5rem;
}
